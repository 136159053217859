import axios from "axios";
import {
  IUser,
  IUserPermissionType,
  IUserPostValues,
  UserAuth0Properties,
} from "../utilities/interfaces";

export const urlUser = process.env.REACT_APP_BACKEND_BASE_URL + "/api/Users";
export const urlUserPermissions =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/UserPermissions";
export const urlUserRoles =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/UserRole";

export async function GetCurrentUserApi(token: any) {
  let user: any = null;
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(`${urlUser}/CurrentUser`, config)
    .then((resp) => {
      user = resp.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [user, errors];
}

export async function GetUserByEmailApi(
  userData: UserAuth0Properties,
  token: string
) {
  let user: any = null;
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .post(`${urlUser}/userByEmail`, userData, config)
    .then((resp) => {
      user = resp;
    })
    .catch((error) => {
      errors = error;
    });
  return [user, errors];
}

export async function DeleteUser(userId: number, token: string) {
  let user: any = null;
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .delete(`${urlUser}/${userId}`, config)
    .then((resp) => {
      user = resp;
    })
    .catch((error) => {
      errors = error;
    });
  return [user, errors];
}

export async function UpdateUserAuth0Id(userData: IUser, token: any) {
  let errors: any = null;
  let userDetails: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let url = urlUser;
  await axios
    .put(url + "/updateAuthId", userData, config)
    .then((response) => {
      userDetails = response;
    })
    .catch((error) => {
      errors = error;
    });
  return [userDetails, errors];
}

export async function UpdateUserDetailsApi(userData: IUser, token: any) {
  let errors: any = null;
  let userDetails: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let url = urlUser;
  await axios
    .put(url, userData, config)
    .then((response) => {
      userDetails = response;
    })
    .catch((error) => {
      errors = error;
    });
  return [userDetails, errors];
}

export async function GetAllUsersAPI(token: any) {
  let users: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(urlUser, config)
    .then((response) => {
      users = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [users, errors];
}

export async function GetUsersByDepartmentId(
  departmentId: number,
  token: string
) {
  let users: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(urlUser + `/GetAllUsersByDeptId/${departmentId}`, config)
    .then((response) => {
      users = response;
    })
    .catch((error) => {
      errors = error;
    });
  return [users, errors];
}

export async function GetAllUserPermissionTypes(token: any) {
  let userRoles: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(urlUserPermissions + "/AllUserPermissionTypes", config)
    .then((response) => {
      userRoles = response;
    })
    .catch((error) => {
      errors = error;
    });
  return [userRoles, errors];
}

export async function AddNewUserApi(userDetails: IUserPostValues, token: any) {
  let newUserDetails: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(urlUser + `/AddUser`, userDetails, config)
    .then((resp) => {
      newUserDetails = resp.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [newUserDetails, errors];
}

export async function AddNewUserExistingOrganisation(
  organisationId: number,
  user: IUserPostValues,
  token: string
) {
  let newUserDetails: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(urlUser + `/AddUser/Organisation/${organisationId}`, user, config)
    .then((resp) => {
      newUserDetails = resp.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [newUserDetails, errors];
}

//-------------------------------------------------User Permission Types API Calls------------------------------------------------------------------------

export async function AddNewUserPermissionType(
  userPermissionType: IUserPermissionType,
  token: any
) {
  let newUserPermissionType: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(urlUserPermissions, userPermissionType, config)
    .then((resp) => {
      newUserPermissionType = resp.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [newUserPermissionType, errors];
}

export async function UpdateUserPermissionType(
  userPermissionType: IUserPermissionType,
  token: any
) {
  let errors: any = null;
  let userPermissionTypeResponse: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .put(urlUserPermissions, userPermissionType, config)
    .then((response) => {
      userPermissionTypeResponse = response;
    })
    .catch((error) => {
      errors = error;
    });
  return [userPermissionTypeResponse, errors];
}

export async function GetAllUserPermissionTypeApi(token: string) {
  let departments: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(`${urlUserPermissions}/AllUserPermissionTypes`, config)
    .then((response) => {
      departments = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [departments, errors];
}

export async function DeleteUserPermission(userPermissionId: number, token: string) {
  let user: any = null;
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .delete(`${urlUserPermissions}/${userPermissionId}`, config)
    .then((resp) => {
      user = resp;
    })
    .catch((error) => {
      errors = error;
    });
  return [user, errors];
}
