import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

interface Auth0MiddlewareProps {
  children: React.ReactNode;
}

const Auth0Middleware: React.FC<Auth0MiddlewareProps> = ({ children }) => {
  // Code added by Fernando Silva 14/10/2021

  
  const domain: string = "engiseek.au.auth0.com";
  const clientId: string = "VYvAFkqiV0Qx0GoL58SM3QnGq4HuRByN";
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin + "/home"}
        onRedirectCallback={onRedirectCallback}
        audience='l33t-backend-services'
        scope='openid profile email rw:jobs'
        useRefreshTokens={true}
        cacheLocation='localstorage'
      >
        {/* Components using Auth0 */}
        {children}
      </Auth0Provider>
    </>
  );
};

export default Auth0Middleware;
