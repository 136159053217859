import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Spinner from "../components/Spinner/Spinner";
import SpinnerWithoutLogo from "../components/Spinner/SpinnerWithoutLogo";
import {
  BronzeStates,
  CopperStates,
  DateFormatOptions,
  GoldStates,
  QuoteOutcomeEnum,
  QuotePhases,
  QuoteTypeHistoryEnum,
  SilverStates,
} from "./enumerators";
import {
  IAddress,
  IClientRepresentative,
  ICollaborationRequestsModel,
  INewQuoteOptions,
  IQuoteGetValues,
  IQuoteHistory,
  IQuoteHomePageViewModel,
  IQuoteOutcome,
  ISelectedQuoteHistoryToExcel,
  IUser,
} from "./interfaces";
import { BlockBlobClient, newPipeline } from "@azure/storage-blob";
import { RootState } from "../store/store";
import { FormatHistoryMessageFunction } from "../components/QuoteHistorySection/functions";

// Returns the token of the logged user
export function GetUserToken(): string {
  const token: string = useSelector(
    (state: RootState) => state.authentication.accessToken
  );
  return token;
}
export function TitleHistoryState(quote: IQuoteGetValues) {
  let historyLogMessage = "";
  quote?.quoteHistory?.map((e: IQuoteHistory) => {
    const fromPhaseId = parseInt(e?.from?.split(",")[0]!);
    const fromStateId = parseInt(e?.from?.split(",")[1]!);

    const toPhaseId = parseInt(e?.to?.split(",")[0]!);
    const toStateId = parseInt(e?.to?.split(",")[1]!);

    let previousPhaseMessage = "";
    let newPhaseMessage = "";

    switch (toPhaseId!) {
      case QuotePhases.BRONZE:
        newPhaseMessage = `${QuotePhases[toPhaseId]} (${BronzeStates[toStateId]})`;
        break;
      case QuotePhases.SILVER:
        newPhaseMessage = `${QuotePhases[toPhaseId]} (${SilverStates[toStateId]})`;
        break;
      case QuotePhases.GOLD:
        newPhaseMessage = `${QuotePhases[toPhaseId]} (${GoldStates[toStateId]})`;
        break;
    }
    switch (fromPhaseId) {
      case QuotePhases.COPPER:
        previousPhaseMessage = `${QuotePhases[fromPhaseId]} (${CopperStates[fromStateId]})`;
        break;
      case QuotePhases.BRONZE:
        previousPhaseMessage = `${QuotePhases[fromPhaseId]} (${BronzeStates[fromStateId]})`;
        break;
      case QuotePhases.SILVER:
        previousPhaseMessage = `${QuotePhases[fromPhaseId]} (${SilverStates[fromStateId]})`;
        break;
      case QuotePhases.GOLD:
        previousPhaseMessage = `${QuotePhases[fromPhaseId]} (${GoldStates[fromStateId]})`;
        break;
    }

    historyLogMessage += `\n____Date: ${FormatDate(
      e.date,
      true
    )}   changed from ${previousPhaseMessage?.toUpperCase()} to ${newPhaseMessage?.toUpperCase()}`;
  });

  return <label>{historyLogMessage}</label>;
}

export function HistoryStates(quote: IQuoteGetValues) {

  let historyLog: any = [];
  if (quote?.quoteHistory) {
    quote?.quoteHistory.map((e: IQuoteHistory) => {
      const fromPhaseId = parseInt(e?.from?.split(",")[0]!);
      const fromStateId = parseInt(e?.from?.split(",")[1]!);

      const toPhaseId = parseInt(e?.to?.split(",")[0]!);
      const toStateId = parseInt(e?.to?.split(",")[1]!);

      let previousPhaseMessage = "";
      let newPhaseMessage = "";

      if (e.type === QuoteTypeHistoryEnum.QUOTE_STATE_CHANGE) {
        switch (toPhaseId!) {
          case QuotePhases.COPPER:
            newPhaseMessage = `${QuotePhases[toPhaseId]} (${CopperStates[toStateId]})`;
            break;
          case QuotePhases.BRONZE:
            newPhaseMessage = `${QuotePhases[toPhaseId]} (${BronzeStates[toStateId]})`;
            break;
          case QuotePhases.SILVER:
            newPhaseMessage = `${QuotePhases[toPhaseId]} (${SilverStates[toStateId]})`;
            break;
          case QuotePhases.GOLD:
            newPhaseMessage = `${QuotePhases[toPhaseId]} (${GoldStates[toStateId]})`;
            break;
        }
        switch (fromPhaseId) {
          case QuotePhases.COPPER:
            previousPhaseMessage = `${QuotePhases[fromPhaseId]} (${CopperStates[fromStateId]})`;
            break;
          case QuotePhases.BRONZE:
            previousPhaseMessage = `${QuotePhases[fromPhaseId]} (${BronzeStates[fromStateId]})`;
            break;
          case QuotePhases.SILVER:
            previousPhaseMessage = `${QuotePhases[fromPhaseId]} (${SilverStates[fromStateId]})`;
            break;
          case QuotePhases.GOLD:
            previousPhaseMessage = `${QuotePhases[fromPhaseId]} (${GoldStates[fromStateId]})`;
            break;
        }
      } else {
        previousPhaseMessage = "";
        newPhaseMessage = "";
        if (e.type === QuoteTypeHistoryEnum.QUOTE_SUBMIT) {
          newPhaseMessage = "SUBMMITTED";
        }

        if (e.type === QuoteTypeHistoryEnum.QUOTE_SAVE) {
          newPhaseMessage = "QUOTE CREATED";
          previousPhaseMessage = "";
        }
      }
      let newDate = new Date(FormatDateTime(e.date)[0]).toLocaleDateString(
        DateFormatOptions.enAU
      );
      let dateArray = newDate.split("/");
      newDate = `${dateArray[0]}/${dateArray[1]}/${dateArray[2]}`;
      const t1 = new Date(e.date).getTime();
      const t2 = new Date(quote.dueDate).getTime();
      
      
      let ts = (t2 - t1) / 1000;
      var daysToDueDate = Math.floor(ts / (3600 * 24));
      // calculate (and subtract) whole days
      let delta = ts - daysToDueDate * 86400;
      // calculate (and subtract) whole hours
      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;
      // calculate (and subtract) whole minutes
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;
      // what's left is seconds
      var seconds = delta % 60;
      var timesToDueDate = hours + ":" + minutes;
      let totalDays = daysToDueDate * 24;
      var hoursToDueDate =
        hours +
        totalDays +
        "." +
        parseFloat(((minutes * 100) / 60).toString()).toFixed(0);

      historyLog.push({
        email: e.email.toLocaleLowerCase(),
        date: newDate,
        dateOrigin: e.date,
        duedateType: quote.dueDateType,
        time: FormatDateTime(e.date)[1].substring(0, 5),
        days: daysToDueDate,
        hours: timesToDueDate,
        hoursTo: hoursToDueDate,
        from: previousPhaseMessage?.toUpperCase(),
        to: newPhaseMessage?.toUpperCase(),
      });
    });

    return historyLog;
  } else return [];
}

export function HistoryStates2(quote: ISelectedQuoteHistoryToExcel) {

  let historyLog: any = [];
  if (quote) {
   
      const fromPhaseId = parseInt(quote?.from?.split(",")[0]!);
      const fromStateId = parseInt(quote?.from?.split(",")[1]!);
      const toPhaseId = parseInt(quote?.to?.split(",")[0]!);
      const toStateId = parseInt(quote?.to?.split(",")[1]!);

      let previousPhaseMessage = "";
      let newPhaseMessage = "";

      if (quote.type === QuoteTypeHistoryEnum.QUOTE_STATE_CHANGE) {
        switch (toPhaseId!) {
          case QuotePhases.COPPER:
            newPhaseMessage = `${QuotePhases[toPhaseId]} (${CopperStates[toStateId]})`;
            break;
          case QuotePhases.BRONZE:
            newPhaseMessage = `${QuotePhases[toPhaseId]} (${BronzeStates[toStateId]})`;
            break;
          case QuotePhases.SILVER:
            newPhaseMessage = `${QuotePhases[toPhaseId]} (${SilverStates[toStateId]})`;
            break;
          case QuotePhases.GOLD:
            newPhaseMessage = `${QuotePhases[toPhaseId]} (${GoldStates[toStateId]})`;
            break;
        }
        switch (fromPhaseId) {
          case QuotePhases.COPPER:
            previousPhaseMessage = `${QuotePhases[fromPhaseId]} (${CopperStates[fromStateId]})`;
            break;
          case QuotePhases.BRONZE:
            previousPhaseMessage = `${QuotePhases[fromPhaseId]} (${BronzeStates[fromStateId]})`;
            break;
          case QuotePhases.SILVER:
            previousPhaseMessage = `${QuotePhases[fromPhaseId]} (${SilverStates[fromStateId]})`;
            break;
          case QuotePhases.GOLD:
            previousPhaseMessage = `${QuotePhases[fromPhaseId]} (${GoldStates[fromStateId]})`;
            break;
        }
      } else {
        previousPhaseMessage = "";
        newPhaseMessage = "";
        if (quote.type === QuoteTypeHistoryEnum.QUOTE_SUBMIT) {
          newPhaseMessage = "SUBMMITTED";
        }

        if (quote.type === QuoteTypeHistoryEnum.QUOTE_SAVE) {
          newPhaseMessage = "QUOTE CREATED";
          previousPhaseMessage = "";
        }
      }
      let newDate = new Date(FormatDateTime(quote.date)[0]).toLocaleDateString(
        DateFormatOptions.enAU
      );
      let dateArray = newDate.split("/");
      newDate = `${dateArray[0]}/${dateArray[1]}/${dateArray[2]}`;
      const t1 = new Date(quote.date).getTime();
      const t2 = new Date(quote.quoteDueDate).getTime();
      let ts = (t2 - t1) / 1000;
      var daysToDueDate = Math.floor(ts / (3600 * 24));
      // calculate (and subtract) whole days
      let delta = ts - daysToDueDate * 86400;
      // calculate (and subtract) whole hours
      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;
      // calculate (and subtract) whole minutes
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;
      // what's left is seconds
      var seconds = delta % 60;
      var timesToDueDate = hours + ":" + minutes;
      let totalDays = daysToDueDate * 24;
      var hoursToDueDate =
        hours +
        totalDays +
        "." +
        parseFloat(((minutes * 100) / 60).toString()).toFixed(0);

      historyLog.push({
        userEmail: quote.userEmail.toLocaleLowerCase(),
        date: newDate,
        quoteDueDate: quote.date,
        dueDateType: quote.dueDateType ,
        time: FormatDateTime(quote.date)[1].substring(0, 5),
        days: daysToDueDate,
        hours: timesToDueDate,
        hoursTo: hoursToDueDate,
        from: previousPhaseMessage?.toUpperCase(),
        to: newPhaseMessage?.toUpperCase(),
      });
   

    return historyLog;
  } else return [];
}





export function GetAllQuotes(
  hideLegacy: boolean | undefined
): IQuoteHomePageViewModel[] {
  const quotes: IQuoteHomePageViewModel[] = useSelector((state: RootState) =>
    hideLegacy ? state.quote.quotesWhenHideLegacy : state.quote.quotes
  );

  return quotes;
}

export function GetAllStates(): any {
  const allStates: any = useSelector((state: RootState) => state.quote);
  return allStates;
}
export function GetAllHistoryStates(): any {
  const allHistoryStates: any = useSelector(
    (state: RootState) => state.quoteHistory
  );
  return allHistoryStates;
}
export function GetCurrentUser() {
  const user_: IUser = useSelector(
    (state: RootState) => state.authentication.user
  );

  
  return user_;
}
// Returns loading spinner
export function ShowLoading() {
  return (
    <React.Fragment>
      <div className="mt-5">
        <Spinner />
      </div>
    </React.Fragment>
  );
}
export const isEmptyString = (str: string) => !str?.length;
//
export function SortColumn(values: any) {
  if (!values || values.length < 2) return values;
  let regx = `"`;
  return values?.sort(function (a: any, b: any) {
    if (!a.name || !b.name) return 0;
    if (
      a.name.trim().replace(regx, "").toLowerCase() <
      b.name.trim().replace(regx, "").toLowerCase()
    )
      return -1;
    if (
      a.name.trim().replace(regx, "").toLowerCase() >
      b.name.trim().replace(regx, "").toLowerCase()
    )
      return 1;
    return 0;
  });
}
export function SortByKey(propertyName: string, values: any) {
  let arrayForSort = [...values];
  if (!arrayForSort || arrayForSort.length < 2) return arrayForSort;
  return arrayForSort?.sort(function (a: any, b: any) {
    if (!a[propertyName] || !b[propertyName]) return 0;
    if (
      a[propertyName].trim().toLowerCase() <
      b[propertyName].trim().toLowerCase()
    )
      return -1;
    if (
      a[propertyName].trim().toLowerCase() >
      b[propertyName].trim().toLowerCase()
    )
      return 1;
    return 0;
  });
}
// Returns update spinner
export function ShowUpdating(message: string = "") {
  return (
    <React.Fragment>
      <div className="mt-5">
        <Spinner message={message} />
      </div>
    </React.Fragment>
  );
}
export function ShowLoadingWithoutLogo(message: string = "") {
  return (
    <React.Fragment>
      <div className="mt-5">
        <SpinnerWithoutLogo message={message} />
      </div>
    </React.Fragment>
  );
}
export function uniqByName(arr: any) {
  const unique: any = [];
  arr.map((x: any) =>
    unique.filter(
      (a: any) =>
        a?.firstName.trim() === x?.firstName.trim() &&
        a?.lastName.trim() === x?.lastName.trim()
    ).length > 0
      ? null
      : unique.push(x)
  );
  return unique;
}
export function HandleRedirect(destination: string) {
  window.location.href = `${destination}`;
}
// Returns network error message
export function ShowNetworkErrorMessage(
  refreshButton: boolean = false,
  path: string = ""
) {
  const history = useHistory();
  const refreshPage = (path: string) => {
    history.push(path);
  };
  return (
    <React.Fragment>
      <div className="d-flex justify-content-center">
        <div className="alert text-center bg-alert" role="alert">
          <div>
            <h4>
              <i className="fas fa-exclamation-triangle fa-1x"></i> The server
              is not reachable
            </h4>
          </div>
          <div className="mt-2">
            {!refreshButton && <p>Please try again later.</p>}
            {refreshButton && (
              <p>
                Check your network connection and click in the button below to
                try again.
              </p>
            )}
          </div>

          {refreshButton && path !== "" && (
            <div className="mt-5">
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm text-16 ms-2"
                aria-label="Close"
                onClick={() => refreshPage(path)}
              >
                Refresh page
              </button>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export function HandleCollabortionSelect(
  quote: IQuoteGetValues,
  quotesCollaborations: ICollaborationRequestsModel[],
  allUsers: IUser[]
) {
  let userCollabortors = "";

  quotesCollaborations.filter((e) => {
    if (e.quoteId === quote.id) {
      let usersCollabortion = allUsers.find(
        (item) => item.id === e.bidLeaderId
      );
      if (usersCollabortion) {
        userCollabortors += usersCollabortion.firstName + ",";
      }
    }
  });

  userCollabortors = userCollabortors.slice(
    0,
    userCollabortors.lastIndexOf(",")
  );

  return userCollabortors;
}
// Returns a new data array according to the "pageNumber" and "pageSize" parameters
export function Paginate(
  data: Array<any>,
  pageNumber: number,
  pageSize: number
) {
  let startIndex = (pageNumber - 1) * pageSize;
  // if (searchState && searchState === true) startIndex = 0;
  const dataPaginated = [];
  for (let i = startIndex; i < startIndex + pageSize; i++) {
    if (data[i]) {
      dataPaginated.push(data[i]);
    }
  }
  return dataPaginated;
}
export function FormatDateTime(dateString: string, getTime: boolean = false) {
  return dateString.split("T");

  //return dateString.substring(0, dateString.indexOf("T"));
}
export function FormatDate(dateString: string, getTime: boolean = false) {
  let date = Date.parse(dateString);

  let dateFormated = new Date(date).toLocaleDateString(DateFormatOptions.enAU);
  if (getTime) {
    let time = dateString.split("T")[1].substring(0, 5);
    return `${dateFormated} - ${time}`;
  }
  return dateFormated;
  //return dateString.substring(0, dateString.indexOf("T"));
}
export function FormatOrganisationAddress(address: IAddress) {
  return (
    address.addressLine1 + "," + address.addressLine2 + "," + address.postcode
  );
}
export function GetTimezoneCode(state: string): string {
  switch (state) {
    case "QLD":
      return "Australia/Brisbane";
    case "NSW":
      return "Australia/NSW";
    case "ACT":
      return "Australia/ACT";
    case "VIC":
      return "Australia/Victoria";
    case "TAS":
      return "Australia/Tasmania";
    case "SA":
      return "Australia/South";
    case "NT":
      return "Australia/Darwin";
    case "WA":
      return "Australia/Perth";
  }

  return "";
}

//------------------------------- Render Funtions --------------------------------------

export function GetQuoteOutcomeStyle(outcome: IQuoteOutcome) {
  switch (outcome.id) {
    case QuoteOutcomeEnum.OUTSTANDING:
      return "bg-light-blue text-white";
    case QuoteOutcomeEnum.UNSUCCESSFUL:
      return "bg-red-ligth  text-white";
    case QuoteOutcomeEnum.SUCCESSFUL:
      return "bg-colour-green text-white";
    case QuoteOutcomeEnum.NOT_AWARDED:
      return "bg-yellow text-white";
    default:
      return "bg-colour-dark-gray text-white";
  }
}
// Render quote status in each phase
export function RenderQuoteStatus(
  quote: IQuoteGetValues,
  phase: string,
  status: string,
  days: number,
  quoteID: string = "",
  styleValue: string
) {
  let due_date: string = quote.dueDate;
  let critical = false;
  let alertDays = days;
  let alert_days = days + 1;
  if (phase === "GOLD") {
    alert_days = 4;
  }
  if (quote.dueDateThresholdAlert) {
    alert_days = quote.dueDateThresholdAlert + 1;
    alertDays = quote.dueDateThresholdAlert;
  }

  let critical_days = alertDays;
  let style = "";
  let orange = `${styleValue}  bg-colour-orange`;
  let red = `${styleValue} bg-colour-red`;
  let blue = `${styleValue} bg-colour-blue`;
  let green = `${styleValue} bg-colour-green`;
  let gray = `${styleValue} bg-colour-dark-gray`;

  // Days calculation
  var one_day = 1000 * 60 * 60 * 24;
  let dueDate = Date.parse(due_date.slice(0, 10));
  let today = new Date().getTime();
  let result = (dueDate - today) / one_day;
  let daysToDueDate = Math.ceil(result);
  let timeStatus: string = "On-time";

  // ALERTS STATUS.
  if (result <= critical_days) {
    style = red;
    critical = true;
    timeStatus = "Behind schedule";
  }
  if (result > critical_days && result <= alert_days) {
    style = orange;
    critical = true;
  }
  // COPPER REVIEW
  if (phase === QuotePhases[0] && status === CopperStates[1] && !critical)
    style = blue;
  if (phase === QuotePhases[0] && status === CopperStates[2]) style = green;
  if (phase === QuotePhases[0] && status === CopperStates[3]) style = gray;
  // BRONZE REVIEW
  if (phase === QuotePhases[1] && status === BronzeStates[1] && !critical)
    style = blue;
  if (phase === QuotePhases[1] && status === BronzeStates[2] && !critical)
    style = green;
  if (phase === QuotePhases[1] && status === BronzeStates[3]) style = green;
  if (phase === QuotePhases[1] && status === BronzeStates[4]) style = gray;
  if (phase === QuotePhases[1] && status === BronzeStates[5] && !critical)
    style = orange;
  // SILVER REVIEW
  if (phase === QuotePhases[2] && status === SilverStates[2]) style = green;
  if (phase === QuotePhases[2] && status === SilverStates[3] && !critical)
    style = blue;
  if (phase === QuotePhases[2] && status === SilverStates[4]) style = gray;
  // GOLD REVIEW
  if (phase === QuotePhases[3] && status === GoldStates[2]) style = green;
  if (phase === QuotePhases[3] && status === GoldStates[3] && !critical)
    style = blue;
  if (phase === QuotePhases[3] && status === GoldStates[4]) style = gray;
  if (phase === QuotePhases[3] && status === GoldStates[5]) style = gray;

  return (
    <div
      className={`${style}`}
      title={`Status: ${timeStatus} \nDays to due date: ${daysToDueDate}\nAlert threshold: ${alertDays} days before due date\nGeneral alert rule: ${
        quote.dueDateThresholdAlert === null ? "Yes" : "No"
      }`}
    >
      {status?.replace("_", " ")}
    </div>
  );
}
// Render the Quote Submitted field
export function RenderSubmitted(
  submittedDate: string | undefined,
  style: string
) {
  let green = `${style} text-white  bg-colour-green`;
  let pending = `${style} text-white`;

  if (submittedDate) {
    return <div className={green}> {FormatDate(submittedDate)} </div>;
  } else {
    return <div className={pending}> {""} </div>;
  }
}
export function FormatDateTimeLocalTime(quote: IQuoteGetValues) {
  let dateTime = Date.parse(quote.dueDate);
  let dateObject: string = new Date(dateTime).toLocaleString("en-AU");
  return new Date(dateObject).toLocaleString();
}
export function FormatDateTimeWithTimezone(quote: IQuoteGetValues) {
  let dateTime = Date.parse(quote.dueDate);
  let dateObject: string = new Date(dateTime).toLocaleString("en-AU", {
    timeZone: GetTimezoneCode(quote.organisation.address.province),
  });

  return dateObject;
}
//------------------------------- Time and Date Functions-------------------------------

//Function which takes in a UTC time string and returns a conversion to locatime time.
//e.g. 28/10/2022 - 10:00pm -> 01/03/2022 - 8:00am.
//NOTE: input date format must be in DD-MM-YYYY

export function ConvertTimeFromUTCToLocalTime(utcString: string) {
  let utcDateTime = new Date(utcString);
  let localDateTime = new Date(
    utcDateTime.getTime() - utcDateTime.getTimezoneOffset() * 60 * 1000
  );
  return [
    localDateTime.toLocaleDateString(DateFormatOptions.enAU),
    localDateTime.toLocaleTimeString(),
  ];
}
//NOTE: input is assumed to be in DD-MM-YYY
export function ConvertTimeFromLocalTimeToUTCTime(localDateTimeString: string) {
  let dateString = localDateTimeString.substring(
    0,
    localDateTimeString.indexOf("T")
  );
  let timeString = localDateTimeString.substring(
    localDateTimeString.indexOf("T") + 1,
    localDateTimeString.length
  );

  //Convert date format to YYYY-MM-DD for use in JS Date constructor.
  let year = dateString.substring(
    dateString.lastIndexOf("/") + 1,
    dateString.length
  );
  let day = dateString.substring(0, dateString.indexOf("/"));
  let month = dateString.substring(
    dateString.indexOf("/") + 1,
    dateString.lastIndexOf("/")
  );
  let dateStringFinal = year + "-" + month + "-" + day;
  let finalDateTimeString = dateStringFinal + "T" + timeString;
  let localTime = new Date(finalDateTimeString);

  //construct date object and get UTC time values.
  let utcDate = localTime.getUTCDate().toString();
  let utcMonth = localTime.getUTCMonth().toString();
  let utcYear = localTime.getUTCFullYear().toString();
  let timeIdentifier = localTime.toUTCString().indexOf("G");
  let finishUtcTime = new Date(finalDateTimeString)
    .toUTCString()
    .substring(timeIdentifier - 9, timeIdentifier - 1);

  //Add 1 to month as Jan returns 0 -> should return 0
  utcMonth = (parseInt(utcMonth) + 1).toString();

  //Check whether the month or date is less than 10 -> if yes; add a leading 0 (5 -> 05).
  if (parseInt(utcMonth) < 10) {
    utcMonth = "0" + utcMonth;
  }

  if (parseInt(utcDate) < 10) {
    utcDate = "0" + utcDate;
  }

  let finalDate = utcYear + "-" + utcMonth + "-" + utcDate;

  return [finalDate, finishUtcTime];
}
// Returns a string on the format (YYYY-MM-DD => Exam: 2021-09-01)
export function DateTimeToString(date: Date, withTime: boolean = true) {
  let day = date.getUTCDate();
  let month = date.getUTCMonth() + 1;
  let year = date.getUTCFullYear();
  let hour = date.getUTCHours();
  let minutes = date.getUTCMinutes();
  let seconds = date.getUTCSeconds();
  let dateString;
  if (withTime) {
    dateString = `${year}-${month <= 9 ? "0" + month : month}-${
      day <= 9 ? "0" + day : day
    }-${hour <= 9 ? "0" + hour : hour}:${
      minutes <= 9 ? "0" + minutes : minutes
    }:${seconds <= 9 ? "0" + seconds : seconds}`;
  } else {
    dateString = `${year}-${month <= 9 ? "0" + month : month}-${
      day <= 9 ? "0" + day : day
    }`;
  }
  return dateString;
}
export enum FormatType {
  ddmmyyyy = 1,
  yyyymmdd = 2,
}
// Function to format the date
export function FormatDateOptions(
  date: Date,
  localTime: DateFormatOptions = DateFormatOptions.enAU,
  type: FormatType = FormatType.ddmmyyyy
) {
  if (type === 1) {
    // DD/MM/YYYY
    return new Date(date).toLocaleDateString(localTime);
  }
  if (type === 2) {
    // YYYY-MM-DD
    let newDate = new Date(date).toLocaleDateString(localTime);
    let dateArray = newDate.split("/");
    newDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
    return newDate;
  }
}


export function FormatQuoteDate(dateTimeString: string) {
  return dateTimeString.substring(0, dateTimeString.indexOf("T"));
}
export const CompareDates = (d: string) => {
  let date1 = new Date().getTime();
  let date2 = new Date(d).getTime();

  if (date1 < date2) {
    return 1;
  } else if (date1 > date2) {
    return -1;
  } else {
    return 0;
  }
};
export function GetCurrentQuoteState(quote: IQuoteGetValues): number {
  if (quote.copperPhase?.id != CopperStates.PASS) {
    return QuotePhases.COPPER;
  }
  if (
    quote.copperPhase?.id === CopperStates.PASS &&
    quote.bronzePhase.id !== BronzeStates["BID-OPEN"]
  ) {
    
    return QuotePhases.BRONZE;
  }
  if (
    quote.bronzePhase.id === BronzeStates["BID-OPEN"] &&
    quote.silverPhase.id !== SilverStates.COMPLETED
  ) {
    return QuotePhases.SILVER;
  } else if (quote.silverPhase.id === SilverStates.COMPLETED) {
    return QuotePhases.GOLD;
  }
  return QuotePhases.GOLD;
}



export function HandleTitleReview(type: QuotePhases) {
  switch (type) {
    case QuotePhases.COPPER:
      return "Copper Phases";
    case QuotePhases.BRONZE:
      return "Bronze Phases";
    case QuotePhases.SILVER:
      return "Silver Phases";
    case QuotePhases.GOLD:
      return "Gold Phases";
  }
}
export function CalculateCurrentPhaseState(quote: IQuoteGetValues): string {
  switch (GetCurrentQuoteState(quote)) {
    case QuotePhases.COPPER:
      return quote.copperPhase?.state!;
    case QuotePhases.BRONZE:
      return quote.bronzePhase.state!;
    case QuotePhases.SILVER:
      return quote.silverPhase.state!;
    case QuotePhases.GOLD:
      return quote.goldPhase.state!;
    default:
      return "";
  }
}
export function ShowServerIsNotAvailable() {
  return (
    <div className="border-radius p-2 mt-2 bg-alert">
      <div className="d-flex justify-content-center">
        <div className="ms-3">
          <h6 className="pt-1">
            <i className="fas fa-exclamation-triangle fa-1x "></i> The server is
            not reachable
          </h6>
        </div>
      </div>
    </div>
  );
}
// Take as paramente the organisation information string and returns an object
export function GetABNObject(data: any) {
  let result = null;
  try {
    let splitArray = data.slice(9);
    result = JSON.parse(splitArray.slice(0, -1));
    return result;
  } catch {
    return result;
  }
}

//---------------------------------------------------------------------------------------------------------------------------------------------------

/* AZURE FUNCTIONS*/
export async function azureUpload(sas: any, blob: Blob) {
  const blockBlobClient = new BlockBlobClient(sas, newPipeline());
  return await blockBlobClient.uploadData(blob);
}
export async function azureDownload(sas: any) {
  const blockBlobClient = new BlockBlobClient(sas, newPipeline());
  const response = await blockBlobClient.download();
  return response.blobBody;
}
export async function azureDelete(sas: any) {
  const blockBlobClient = new BlockBlobClient(sas, newPipeline());
  const response = await blockBlobClient.deleteIfExists();
  return response;
}
//---------------------------------------------------------------------------------------------------------------------------------------------------

export function ChunkArray(array: Array<any>, parts: number) {
  let result = [];
  for (let i = parts; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)));
  }
  return result;
}
