import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./components/App";
import streamSaver from "streamsaver";

// import { Ion } from "cesium";
// Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2ODNmN2EyMS1hNTZhLTQ2ODQtYWE1Yy01ZDY2YWM3Njc0ZGUiLCJpZCI6NTUzNTQsImlhdCI6MTYyMDYyODA2NH0.i_hcDv1GEGB9V36DBkKGcENlzNUxs97OwZ5svlj7DzM";

streamSaver.mitm =
  process.env.REACT_APP_FRONTEND_BASE_URL + "/streamsaver/mitm.html";

  
const root = createRoot( document.getElementById("root")!);

root.render(<App />);
