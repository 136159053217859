import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { GetCurrentUserApi } from "../api/userApiCalls";
import {
  resetAuthentication,
  setAccessToken,
  setPermission,
  setUser,
} from "../store/features/authentication/authenticationSlice";

import { IUser } from "../utilities/interfaces";
import { useAppDispatch } from "../store/hooks";

interface AuthenticationMiddlewareProps {
  children: React.ReactNode;
}

const AuthenticationMiddleware: React.FC<AuthenticationMiddlewareProps> = ({
  children,
}) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isAuthenticated || localStorage.isAuthenticated) {
      let tokenValue = "";

      getAccessTokenSilently()
        .then((token) => {
          tokenValue = token;
          localStorage.setItem("isAuthenticated", "true");
          dispatch(setAccessToken(token));
        })
        .then(() => {
          GetCurrentUserApi(tokenValue).then((response) => {
            const currentUser: IUser = response[0];
            if (currentUser) {
              dispatch(setPermission(currentUser.userPermissionId));
            }
            dispatch(setUser(currentUser));
          });
        });
    } else {
      dispatch(resetAuthentication());
    }
  }, [isAuthenticated, localStorage.isAuthenticated, getAccessTokenSilently]);
  return <>{children}</>;
};

export default AuthenticationMiddleware;
