import "../../App.css";
import logo from "../../images/logo.png";

const Spinner = ({ message }: any) => {
  return (
    <div>
      <div className="d-flex justify-content-center mb-4">
        <img className="mt-2" src={logo} alt="L33T" width={60} />
      </div>
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-info" role="status">
          <span className="visually-hidden"></span>
        </div>
        <div>
          {!message && <h4 className="ms-3 pb-1">Loading... </h4>}
          {message && <h4 className="ms-3 pb-1">{message} </h4>}
        </div>
      </div>
    </div>
  );
};

export default Spinner;
