export enum NotificationTypes {
  "NEW_COMMENT" = 0,
}

export enum userPermissionType {
  "administrator" = 0,
  "manager" = 1,
  "read_only" = 2,
}

// export enum QuoteStateEmum {
//   "COPPER" = 0,
//   "BRONZE" = 1,
//   "SILVER" = 2,
//   "GOLD" = 3,
// }
export enum QuotePhases {
  "COPPER" = 0,
  "BRONZE" = 1,
  "SILVER" = 2,
  "GOLD" = 3,
}

export enum QuoteStateEnum {
  "DRAFT",
  "SUBMITTED",
}
export enum QuoteTypeName {
  PANEL = "P",
  QUOTE = "Q",
  LEAD = "L",
  EXPRESSION_INTEREST = "E",
}
// Forta Date to en-AU
export enum DateFormatOptions {
  enAU = "en-AU",
  enUS = "en-US",
}

export enum QuoteTypeHistoryEnum {
  "QUOTE_FIELD_EDIT" = 0,
  "QUOTE_STATE_CHANGE" = 1,
  "QUOTE_DRAFT_SAVE" = 2,
  "FILE_OVERWRITE" = 3,
  "FILE_UPLOAD" = 4,
  "FILE_SOFT_DELETE" = 5,
  "FILE_HARD_DELETE" = 6,
  "SENT_EMAIL_WITH_ATTACHMENTS" = 7,
  "QUOTE_SAVE" = 8,
  "QUOTE_COLLABORATION_REQUEST" = 9,
  "QUOTE_COLLABORATION_REMOVE" = 10,
  "ENGISEEK_PRODUCT_ADD" = 11,
  "ENGISEEK_PRODUCT_REMOVE" = 12,
  "QUOTE_SECONDARY_SOURCE_ADD" = 13,
  "QUOTE_SECONDARY_SOURCE_REMOVE" = 14,
  "QUOTE_SECONDARY_SOURCE_WIPE" = 15,
  "ENGISEEK_PRODUCT_WIPE" = 16,
  "FILE_FOLDER_CHANGE" = 17,
  "QUOTE_SUBMIT" = 18,
  "QUOTE_DELETE" = 19,
  "QUOTE_FOLLOWUP_DELETE" = 20,
  "QUOTE_RFI_DELETE" = 21
}

export enum UserPermissions {
  "ADMIN" = 1,
  "MANAGER" = 2,
  "BID_LEADER" = 3,
  "BIDS_TEAM" = 4,
  "TEAM_MEMBER" = 5,
  "READ_ONLY" = 6,
}




export enum UserStatus {
  "ACTIVE" = 0,
  "BLOCKED" = 1,
  "NEW_USER" = 2,
}

export enum PanelTypes {
  "Local-Buy" = 0,
  "Council" = 1,
  "Federal" = 2,
  "Supplier List" = 3,
  "State Panel" = 4,
  "Local Panel" = 5
}

export enum CopperStates {
  "WAITING" = 1,
  "PASS" = 2,
  "FAIL" = 3,
}

export enum BronzeStates {
  "WAITING" = 1,
  "BID-PENDING" = 2,
  "BID-OPEN" = 3,
  "ABANDON" = 4,
  "RECONSIDER" = 5,
}

export enum SilverStates {
  "COMPLETED" = 2,
  "NOT_COMPLETED" = 3,
  "ABANDON" = 4,
}

export enum GoldStates {
  "COMPLETED" = 2,
  "NOT_COMPLETED" = 3,
  "ABANDON" = 4,
  "NOT-AWARDED" = 5,
}



export enum DueDateTypes {
  "Please Select..." = 0,
  "HARD" = 1,
  "SOFT" = 2,
}

export enum StorageURITypes {
  "DOWNLOAD_URI" = 0,
  "UPLOAD_URI" = 1,
  "DELETE_URI" = 2,
}

export enum QuoteOutcomes {
  "OUTSTANDING" = 1,
}

export enum OrganisationType {
  global = 1,
  client_private = 2,
  client_abn = 3,
  partner = 4,
}

export enum PanelModalType {
  EDIT_PANEL,
  NEW_PANEL,
}

export enum FileStorageFolderOptionEnum {
  QUOTE,
  PROJECT,
}

export enum QuoteOutcomeEnum {
  OUTSTANDING = 1,
  NOT_AWARDED = 2,
  SUCCESSFUL = 3,
  UNSUCCESSFUL = 4,
  RFI = 5
}

export enum SidebarOption {

  departments = 1,
  organisationsTypes = 2,
  marketSections = 3,
  marketServices = 4,
  userPermissions = 5,
  panelRoles = 6,
  quoteSizes = 7,
  quoteSources = 8,
  quoteOutcomes = 9,
  quotePhases = 10,
  copperPhases = 11,
  bronzePhases = 12,
  silverPhases = 13,
  goldPhases = 14,
  WorkflowAlerts = 15,
  EngiSeekProducts = 16,
  fileFolderOptions = 17,
  jurisdictions = 18,
  organisationLevel = 19,

}


export enum ScoreClassification{
  Poor = 1,
  Average = 2,
  Good = 3,
  Excellent =4
}


