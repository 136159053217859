import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INewQuoteOptions } from "../../../utilities/interfaces";
import { QuoteHistoryState } from "./types";

const initialState: QuoteHistoryState = {
  newQuoteOptions: {
    departmentOptions: [],
    marketSectionOptions: [],
    marketServiceOptions: [],
    organisationOptions: [],
    panelOptions: [],
    quoteSizeOptions: [],
    quoteSourceOptions: [],
    organisationTypes: [],
    userPermissionTypes: [],
    engiSeekProducts: [],
    quoteOutcome: [],
  },
};

export const quoteHistorySlice = createSlice({
  name: "quoteHistory",
  initialState,
  reducers: {
    setAllNewQuoteOptionsAction: (
      state,
      action: PayloadAction<INewQuoteOptions>
    ) => {
      state.newQuoteOptions = action.payload;
    },
  },
});

export const { setAllNewQuoteOptionsAction } = quoteHistorySlice.actions;

export default quoteHistorySlice.reducer;
