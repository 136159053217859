import React, { lazy } from "react";
import { Redirect, Route, Router, Switch, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ShowLoading } from "../utilities/functions";
import ProtectedRoute from "../middleware/ProtectedRoute";


interface AppRoutesProps {}

const LandingPage = lazy(() => import("../pages/LandingPage/LandingPage"));
//const HomePage = lazy(() => import("../pages/HomePage/HomePage"));
const HomePage = lazy(() => import("../pages/HomePage/HomePageV2"));
const NewQuotePage = lazy(() => import("../pages/NewQuotePage/NewQuotePage"));
const QuoteDetailsPage = lazy(
  () => import("../pages/QuoteDetailsPage/QuoteDetailsPage")
);
const CostBenefitPage = lazy(() => import("../pages/CostBenefitPage/CostBenefitPage"));
const FeedbackPage = lazy(() => import("../pages/QuoteFeedbackPage/QuoteFeedbackPage"));
const PanelPage = lazy(() => import("../pages/PanelPage/PanelPage"));
  

const OrganisationsPage = lazy(
  () => import("../pages/OrganisationsPage/OrganisationsPage")
);
const UsersPage = lazy(() => import("../pages/UsersPage/UsersPage"));
const UserProfilePage = lazy(
  () => import("../pages/UserProfilePage/UserProfilePage")
);
const ErrorPermissionsPage = lazy(
  () => import("../pages/ErrorPermissionPage/ErrorPermissionPage")
);


const QuoteSettings = lazy(() => import("../pages/SettingsPage/SettingsPage"));
const My404Component = lazy(
  () => import("../pages/ErrorNotFoundPage/ErrorNotFoundPage")
);

//const QuoteSettings = lazy(() => import("../pages/SettingPage/SettingPage"));
const ErrorAccountValidation = lazy(
  () => import("../pages/VerifyAccountPage/verifyAccountPage")
);

// Session control variables
const MAX_SESSION_TIME = 360; // Minutes
let count = 0;

const AppRoutes: React.FC<AppRoutesProps> = () => {
  const { user, isAuthenticated, isLoading, logout, loginWithRedirect } =
    useAuth0();

  const location = useLocation();

  // Redirect user to Auth0 for authentication
  async function LogInUser() {
    await loginWithRedirect();
  }

  // Log out user and clean session and local storages
  async function LogOutUser() {
    sessionStorage.clear();
    localStorage.clear();
    logout();
  }

  // Calculates the time elapsed of the session
  function SessionTimeExpired(time: any) {
    var startDate = new Date(Number(time));
    var endDate = new Date();
    var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    var minutes = seconds / 60;
    return minutes > MAX_SESSION_TIME;
  }

  //  Logs out user if he/she closes the tab or the browser
  if (!localStorage.isAuthenticated) {
    LogOutUser();
  }

  // Log out the user if the SESSION_TIME expire
  if (localStorage.isAuthenticated && count === 0) {
    let date = localStorage.getItem("time");
    if (!date || SessionTimeExpired(date)) {
      LogOutUser().then(() => {
        setTimeout(() => {
          LogInUser();
        }, 200);
      });
    }
    count++;
  }

  // Show while waiting for Auth0 authentication
  if (isLoading) {
    return ShowLoading();
  }

  // Set variables for the session
  if (!localStorage.isAuthenticated) {
    localStorage.setItem("isAuthenticated", "true");
    localStorage.setItem("time", new Date().getTime().toString());
  }

  // If user is registered in Auth0 but email was not verified
  if (isAuthenticated && !user?.email_verified) {
    return (
      <Switch>
        <Redirect path='/home' exact={true} to='/' />;
        <ProtectedRoute
          path='/errorAccountValidation'
          exact={true}
          component={ErrorAccountValidation}
        />
       {/* <ProtectedRoute path="/costBenefit"  exact={true} component={CostBenefitPage }/> */}
        <Redirect to='/errorAccountValidation' />
      </Switch>
    );
  }

  // If user is authenticated by Auth0
  else if (isAuthenticated) {
    return (
      <Switch>
        <Redirect path='/' exact={true} to='/home' />;
        <ProtectedRoute path='/home' exact={true} component={HomePage} />
        {/* <ProtectedRoute path='/testhome' exact={true} component={TestHomePage} /> */}
        <ProtectedRoute
          path='/errorAccountValidation'
          exact={true}
          component={ErrorAccountValidation}
        />
        <ProtectedRoute
          path='/newQuote'
          exact={true}
          component={NewQuotePage}
        />
         <ProtectedRoute
          path='/panels'
          exact
          component={PanelPage}
        />
        <ProtectedRoute
          path='/organisations'
          exact
          component={OrganisationsPage}
        />
        <ProtectedRoute path='/users' exact={true} component={UsersPage} />
        <ProtectedRoute
          path='/quoteDetails/:quoteId'
          exact
          component={QuoteDetailsPage}
        />
        <ProtectedRoute
          path='/profile'
          exact={true}
          component={UserProfilePage}
        />
        <ProtectedRoute
          path='/quoteSettings'
          exact={true}
          component={QuoteSettings}
        />
        <ProtectedRoute path="/costBenefit/:quoteId" exact={true} component={CostBenefitPage} />
        <ProtectedRoute path="/feedback/:quoteId" exact={true} component={FeedbackPage }/>
        <Route
          path='/errorPermission'
          exact={true}
          component={ErrorPermissionsPage}
        />
        <Route path='*' exact={true} component={My404Component} />
        
      </Switch>
    );
  }
    
   
    

  // If user is not authenticated by Auth0
  else if (!isAuthenticated) {
    return (
      <Switch>
        <Redirect path='/home' exact={true} to='/errorPermission' />;
        <Route
          path='/errorPermission'
          exact={true}
          component={ErrorPermissionsPage}
        />
        <Route path='/' exact={true} component={LandingPage} />
        <Route path='*' exact={true} component={My404Component} />
      </Switch>
    );
  } else {
    return null;
  }
};

export default AppRoutes;
