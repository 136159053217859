import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IDepartment,
  IMarketSection,
  IMarketService,
  IQuoteHomePageViewModel,
  IQuoteOutcome,
  IQuotePhaseOptions,
  IQuoteSize,
  IQuoteSource,
  IUser,
} from "../../../utilities/interfaces";
import { QuoteState } from "./types";

const initialState: QuoteState = {
  quotes: [],
  quotesWhenHideLegacy: [],
  quotePhaseOptions: {
    copperStateOptions: [],
    bronzeStateOptions: [],
    silverStateOptions: [],
    goldStateOptions: [],
  },
  marketSections: [],
  marketServices: [],
  departments: [],
  allUsers: [],
  qouteSources: [],
  quoteSizes: [],
  qouteOutComes: [],
};

/**
 * Authentication slice combining actions and reducer
 */
export const quoteHomeSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    setAllQuotesAction: (
      state,
      action: PayloadAction<IQuoteHomePageViewModel[]>
    ) => {
      state.quotes = action.payload;
    },
    setAllQuoteshideLegacyAction: (
      state,
      action: PayloadAction<IQuoteHomePageViewModel[]>
    ) => {
      state.quotesWhenHideLegacy = action.payload;
    },
    setquotePhaseOptionsAction: (
      state,
      action: PayloadAction<IQuotePhaseOptions>
    ) => {
      state.quotePhaseOptions = action.payload;
    },
    setMarketSectionsOptionsAction: (
      state,
      action: PayloadAction<IMarketSection[]>
    ) => {
      state.marketSections = action.payload;
    },
    setMarketServicesOptionsAction: (
      state,
      action: PayloadAction<IMarketService[]>
    ) => {
      state.marketServices = action.payload;
    },
    setDepartmentsAction: (state, action: PayloadAction<IDepartment[]>) => {
      state.departments = action.payload;
    },
    setAllQuoteSourcesAction: (
      state,
      action: PayloadAction<IQuoteSource[]>
    ) => {
      state.qouteSources = action.payload;
    },
    setAllQuoteOutcomesAction: (
      state,
      action: PayloadAction<IQuoteOutcome[]>
    ) => {
      state.qouteOutComes = action.payload;
    },
    setQuotesSizesAction: (state, action: PayloadAction<IQuoteSize[]>) => {
      state.quoteSizes = action.payload;
    },
    setAllUsersAction: (state, action: PayloadAction<IUser[]>) => {
      state.allUsers = action.payload;
    },
  },
});

export const {
  setAllQuotesAction,
  setAllQuoteshideLegacyAction,
  setquotePhaseOptionsAction,
  setMarketSectionsOptionsAction,
  setMarketServicesOptionsAction,
  setDepartmentsAction,
  setQuotesSizesAction,
  setAllUsersAction,
  setAllQuoteOutcomesAction,
  setAllQuoteSourcesAction,
} = quoteHomeSlice.actions;

export default quoteHomeSlice.reducer;
